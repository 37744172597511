<template>
  <v-container class="content ma-0 pa-4">
    <v-row v-if="!loading">
      <v-row>
        <v-col
          v-for="(item, index) in items"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <base-material-stats-card
            :title="item.title"
            :icon="item.icon"
            :value="item.value"
            :color="item.color"
            :sub-icon="item.subicon"
            :sub-icon-color="item.subiconcolor"
            :sub-text="item.subtext"
            :elevation="item.elevation"
            :action="item.action"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="graficoConsultor">
          <base-material-chart-card
            id="rascunhos"
            :data="relconsultor3.data"
            :options="optionsTec3"
            type="Bar"
            color="primary"
            title="Rascunhos por Consultor"
            icon="mdi-chart-donut"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            sub-text-left="Realizados até o momento"
            elevation="6"
            min-height="500"
          />
        </v-col>
        <v-col cols="12" class="graficoConsultor">
          <base-material-chart-card
            :data="relconsultor1.data"
            :options="optionsTec1"
            type="Bar"
            color="primary"
            title="Relatórios por Consultor no Último Mês"
            icon="mdi-chart-donut"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            sub-text-left="Último mês"
            elevation="6"
            min-height="500"
          />
        </v-col>
        <v-col cols="12" class="graficoConsultor">
          <base-material-chart-card
            :data="relconsultor2.data"
            :options="optionsTec2"
            type="Bar"
            color="primary"
            title="Relatórios por Consultor no Mês Atual"
            icon="mdi-chart-donut"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            sub-text-left="Realizados até o momento"
            elevation="6"
          />
        </v-col>
        <v-col cols="12" sm="12" lg="12" xl="12" class="grafico">
          <base-material-chart-card
            :data="compvisitas.data"
            :options="optionsBar"
            type="Line"
            color="primary"
            title="Comparativo de Relatórios Realizados (Último Mês x Mês Atual)"
            icon="mdi-chart-line"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            sub-text-left="Últimos 30 dias"
            elevation="6"
          />
        </v-col>
      </v-row>
    </v-row>
    <v-row
      v-else
      justify="center"
      align="center"
      class="pa-4"
      style="height: 50vh"
    >
      <div style="text-align: center">
        <v-progress-circular :size="70" color="primary" indeterminate />
        <h3 class="mt-4">Preparando visualização...</h3>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import Legenda from 'chartist-plugin-axistitle'
import Tooltips from 'chartist-plugin-tooltips-updated'
import { mapState } from 'vuex'
export default {
  name: 'DashboardN',
  data() {
    return {
      loading: true,
      dashboard: null,
      items: [],
      relconsultor1: null,
      relconsultor2: null,
      relconsultor3: null,
      compvisitas: null,
      optionsBar: {
        axisX: {
          offset: 30,
        },
        axisY: {
          onlyInteger: true,
          offset: 50,
        },
        lineSmooth: this.$chartist.Interpolation.monotoneCubic({
          tension: 0,
        }),
        height: '100%',
        low: 0,
        showArea: false,
        plugins: [
          Tooltips({
            appendToBody: false,
            tooltipFnc: function (meta, value) {
              return meta + '<br />' + value + ' Relatórios'
            },
          }),
          Legenda({
            axisX: {
              axisTitle: 'Dias do Mês',
              axisClass: 'ct-axis-title',
              offset: { x: 0, y: 40 },
              textAnchor: 'middle',
            },
            axisY: {
              axisTitle: 'Nº de Relatórios',
              axisClass: 'ct-axis-title',
              offset: { x: 0, y: -1 },
              flipTitle: false,
            },
          }),
        ],
        chartPadding: {
          top: 30,
          right: 20,
          bottom: 0,
          left: 0,
        },
      },
      optionsTec1: {
        horizontalBars: true,
        axisX: {
          showGrid: false,
          onlyInteger: true,
        },
        axisY: {
          offset: 200,
          showGrid: false,
        },
        lineSmooth: this.$chartist.Interpolation.monotoneCubic({
          tension: 0,
        }),
        height: '100%',
        low: 0,
        showArea: false,
        plugins: [
          Tooltips({
            appendToBody: false,
            tooltipFnc: function (meta, value) {
              return meta + '<br />' + value + ' Relatórios'
            },
          }),
        ],
        chartPadding: {
          top: 0,
          right: 20,
          bottom: 0,
          left: 0,
        },
      },
      optionsTec2: {
        horizontalBars: true,
        axisX: {
          showGrid: false,
          onlyInteger: true,
        },
        axisY: {
          offset: 200,
          showGrid: false,
        },
        lineSmooth: this.$chartist.Interpolation.monotoneCubic({
          tension: 0,
        }),
        height: '100%',
        low: 0,
        showArea: false,
        plugins: [
          Tooltips({
            appendToBody: false,
            tooltipFnc: function (meta, value) {
              return meta + '<br />' + value + ' Relatórios'
            },
          }),
        ],
        chartPadding: {
          top: 0,
          right: 20,
          bottom: 0,
          left: 0,
        },
      },
      optionsTec3: {
        horizontalBars: true,
        axisX: {
          showGrid: false,
          onlyInteger: true,
        },
        axisY: {
          offset: 200,
          showGrid: false,
        },
        lineSmooth: this.$chartist.Interpolation.monotoneCubic({
          tension: 0,
        }),
        height: '100%',
        low: 0,
        showArea: false,
        plugins: [
          Tooltips({
            appendToBody: false,
            tooltipFnc: function (meta, value) {
              return meta + '<br />' + value + ' Rascunhos'
            },
          }),
        ],
        chartPadding: {
          top: 0,
          right: 20,
          bottom: 0,
          left: 0,
        },
      },
    }
  },
  computed: {
    ...mapState({ user: state => state.user }),
  },
  mounted() {
    this.getDashboard()
  },
  methods: {
    calc1(value, idx) {
      return (
        value +
        ' ' +
        Math.round(
          (this.relconsultor1.data.series[idx].value /
            this.relconsultor1.data.series.reduce(function (a, b) {
              return a + b.value
            }, 0)) *
            100,
        ) +
        '%'
      )
    },
    calc2(value, idx) {
      return (
        value +
        ' ' +
        Math.round(
          (this.relconsultor2.data.series[idx].value /
            this.relconsultor2.data.series.reduce(function (a, b) {
              return a + b.value
            }, 0)) *
            100,
        ) +
        '%'
      )
    },
    agroindustrias() {
      this.$router.push({ path: 'agroindustrias' }).catch(() => {})
    },
    fazendas() {
      this.$router.push({ path: 'fazendas' }).catch(() => {})
    },
    gestores() {
      this.$router.push({ path: 'gestores' }).catch(() => {})
    },
    consultores() {
      this.$router.push({ path: 'consultores' }).catch(() => {})
    },
    relVisitaGeral() {
      this.$router.push({ path: 'relatorios/geral' }).catch(() => {})
    },
    relCCS() {
      this.$router.push({ path: 'relatorios/ccs' }).catch(() => {})
    },
    relCPP() {
      this.$router.push({ path: 'relatorios/cpp' }).catch(() => {})
    },
    relEvolui() {
      this.$router.push({ path: 'relatorios/evoluileite' }).catch(() => {})
    },
    relBoasPraticas() {
      this.$router.push({ path: 'relatorios/boaspraticas' }).catch(() => {})
    },
    relATB() {
      this.$router.push({ path: 'relatorios/atb' }).catch(() => {})
    },
    relFraude() {
      this.$router.push({ path: 'relatorios/fraudes' }).catch(() => {})
    },
    relBonolat() {
      this.$router.push({ path: 'relatorios/bonolat' }).catch(() => {})
    },
    relEducampo() {
      this.$router.push({ path: 'relatorios/educampo' }).catch(() => {})
    },
    relRascunhos() {
      location.href = '#rascunhos'
    },
    getDashboard() {
      this.loading = true
      this.api.get.dashboard().then(data => {
        this.relconsultor1 = data.grafico1
        this.relconsultor2 = data.grafico2
        this.compvisitas = data.grafico3
        this.relconsultor3 = data.grafico4
        this.items.push({
          title: 'Agroindústrias',
          icon: 'mdi-factory',
          value: String(data.QtdAgroindustrias),
          color: 'blue light-2',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Cadastradas até o momento',
          elevation: '6',
          action: this.agroindustrias,
        })
        this.items.push({
          title: 'Gestores',
          icon: 'mdi-account-tie',
          value: String(data.QtdGestores),
          color: 'orange darken-2',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Cadastrados até o momento',
          elevation: '6',
          action: this.gestores,
        })
        this.items.push({
          title: 'Consultores',
          icon: 'mdi-account',
          value: String(data.QtdConsultores),
          color: 'orange darken-4',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Cadastrados até o momento',
          elevation: '6',
          action: this.consultores,
        })
        this.items.push({
          title: 'Fazendas',
          icon: 'mdi-home-flood',
          value: String(data.QtdFazendas),
          color: 'success',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Atendidas até o momento',
          elevation: '6',
          action: this.fazendas,
        })
        this.items.push({
          title: 'Relatórios em Rascunhos',
          icon: 'mdi-clipboard-alert',
          value: String(data.QtdRascunhos),
          color: 'green darken-4',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relRascunhos,
        })
        this.items.push({
          title: 'Relatórios Visita Geral',
          icon: 'mdi-clipboard-list',
          value: String(data.QtdRelVisita),
          color: 'primary',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relVisitaGeral,
        })
        this.items.push({
          title: 'Relatórios CCS',
          icon: 'mdi-clipboard-text',
          value: String(data.QtdRelCCS),
          color: 'lime darken-2',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relCCS,
        })
        this.items.push({
          title: 'Relatórios CPP',
          icon: 'mdi-clipboard-edit',
          value: String(data.QtdRelCPP),
          color: 'red darken-4',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relCPP,
        })
        this.items.push({
          title: 'Relatório de Evolui Leite',
          icon: 'mdi-clipboard-pulse',
          value: String(data.QtdRelEvolui),
          color: 'purple darken-4',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relEvolui,
        })
        this.items.push({
          title: 'Relatórios Boas Práticas',
          icon: 'mdi-clipboard-text-multiple',
          value: String(data.QtdBoasPraticas),
          color: 'teal darken-4',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relBoasPraticas,
        })
        this.items.push({
          title: 'Relatórios de Resíduos de ATB',
          icon: 'mdi-clipboard-file',
          value: String(data.QtdRelATB),
          color: 'pink darken-4',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relATB,
        })
        this.items.push({
          title: 'Relatórios de fraudes',
          icon: 'mdi-clipboard-text-play',
          value: String(data.QtdRelFraudes),
          color: 'yellow darken-2',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relFraude,
        })
        this.items.push({
          title: 'Relatório de visita Bonolat',
          icon: 'mdi-clipboard-file',
          value: String(data.QtdRelBonolat),
          color: 'grey darken-3',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relBonolat,
        })
        this.items.push({
          title: 'Relatório Educampo',
          icon: 'mdi-clipboard-multiple',
          value: String(data.QtdRelEducampo),
          color: 'teal darken-2',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relEducampo,
        })
        this.items.push({
          title: 'Relatório de embriões',
          icon: 'mdi-clipboard-multiple',
          value: String(data.QtdRelEmbrioes),
          color: 'blue darken-2',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relEmbrioes,
        })
        this.items.push({
          title: 'Relatório Lactaleite',
          icon: 'mdi-clipboard-multiple',
          value: String(data.QtdRelLactaleite),
          color: 'pink darken-2',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relLactaleite,
        })
        this.items.push({
          title: 'Entrega de materiais',
          icon: 'mdi-clipboard-multiple',
          value: String(data.QtdRelEntrega),
          color: 'purple darken-2',
          subicon: 'mdi-clock-outline',
          subiconcolor: 'info',
          subtext: 'Realizados até o momento',
          elevation: '6',
          action: this.relEntrega,
        })
        this.loading = false
      })
    },
  },
}
</script>
<style scope lang="scss">
.grafico .ct-square {
  height: 300px;
}

.grafico .v-card--material-chart .v-card--material__heading {
  max-height: 330px;
}

.grafico .v-card--material-chart .theme--dark.v-card {
  background-color: white !important;
}

.grafico .ct-labels {
  fill: '#143693';
}

.graficoConsultor .ct-square {
  max-height: 450px;
}

.graficoConsultor .v-card--material-chart .v-card--material__heading .ct-bar {
  stroke-width: 18px;
}

.graficoConsultor .v-card--material-chart .theme--dark.v-card {
  background-color: white !important;
}

.graficoConsultor .ct-labels {
  fill: '#143693';
}
</style>
